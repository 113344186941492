import { Grid } from '@mui/material'
import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const PublicRoute = (props) => {

    const { component: Component } = props

    return <Grid container >
        <Grid container item>
            <Navbar></Navbar>
        </Grid>
        <Grid container item>
            <Component {...props} />
        </Grid>
        <Grid container item>
            <Footer />
        </Grid>
    </Grid>
}

export default PublicRoute

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Grid, TextField, useMediaQuery } from '@mui/material';
import { getTheme } from '../../utils/theme';
import InputBox from './InputBox'
import PrimaryButton from "../PrimaryButton";


const ContactFields = () => {
    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));

    const fields = [
        {
            label: " İsminiz ve Soyadınız",
            StartIcon: AccountCircleIcon
        },
        {
            label: " Email Adresiniz",
            StartIcon: EmailIcon
        },
        {
            label: " Telefon Numaranız",
            StartIcon: PhoneInTalkIcon
        },
        {
            label: " Mail Konusu",
            StartIcon: TrackChangesIcon
        },
    ]

    const styles = {
        root: { paddingLeft: isLaptop ? "0vh" : "15vh" },
        box: {
            bgcolor: 'grey.200',
            border: 1,
            borderRadius: '1vh',
            borderColor: "#9e9e9e",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1vh",
            '&:hover': {
                borderColor: "rgba(0, 0, 0, 0.87)",
                cursor: "pointer"
            },
        },
        typography: {
            fontSize: isLaptop ? "1.5vh" : "3vh",
            color: 'gray',
            display: "inline",
            ml: "1.3vh"
        },
        textField: {
            bgcolor: 'grey.200',
            border: 1,
            borderRadius: '1vh',
            borderColor: "#9e9e9e",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1vh",
            '&:hover': {
                borderColor: "rgba(0, 0, 0, 0.87)",
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: "none",
            },
        }
    }

    return <Grid container spacing={"3vh"} sx={styles.root}>
        {fields.map(field => {
            return <Grid item xs={isLaptop ? 12 : 6}>
                <InputBox label={field.label} StartIcon={field.StartIcon} />
            </Grid>
        })}

        <Grid item xs={12}>
            <Grid item sx={styles.textField}>
                <TextField
                    label={'Mailiniz'}
                    // value={comment}
                    onChange={e => {
                        // setComment(e.target.value);
                    }}
                    // sx={styles.textField}
                    minRows={4}
                    fullWidth
                    multiline
                    variant="outlined"
                    InputLabelProps={{ style: { fontSize: isLaptop ? "1.25vh" : "2.5vh", justifySelf: "center", color: "gry" } }}
                    inputProps={{ maxLength: 512, style: { fontSize: isLaptop ? "1.5vh" : "3vh", justifySelf: "center" } }}
                />
            </Grid>
        </Grid>
        <Grid item xs={12} textAlign="end">
            <PrimaryButton text="Gönder" />
        </Grid>
    </Grid>
}
export default ContactFields
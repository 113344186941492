import { Grid, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { getTheme } from '../../utils/theme';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const images = [
//     {
//         label: 'San Francisco – Oakland Bay Bridge, United States',
//         imgPath:
//             'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
//     },
//     {
//         label: 'Bird',
//         imgPath:
//             'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
//     },
//     {
//         label: 'Bali, Indonesia',
//         imgPath:
//             'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
//     },
//     {
//         label: 'Goč, Serbia',
//         imgPath:
//             'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
//     },
// ];

function SwipeableContent(props) {
    const { slide: images } = props
    console.log(images, "images");
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };



    const styles = {

        title: {
            fontFamily: 'Bold',
            fontSize: "1.875rem",
        },
        paper: {
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: 'transparent',
            justifyContent: 'center',
        },
        stepper: {
            mt: "0.5rem",
            bgcolor: 'transparent',
            // justifyContent: 'center',
            '& .MuiMobileStepper-dot': {
                width: isTablet ? 12 : 18,
                height: isTablet ? 12 : 18,
                borderRadius: 20,
                backgroundColor: "#C1C1C1",
                margin: "0px 6px"
            },
            '& .MuiMobileStepper-dotActive': {
                width: isTablet ? 36 : 54,
                backgroundColor: "#33BBC5"

            }
        },
        infoContainer: {
            ml: "1px",
            p: 2,
            pl: 0,
            "& .MuiTypography-root": {
                textAlign: "center"
            },
            display: 'flex',
        },
        infoIcon: {
            mt: "5px",
            mr: 2,
            width: 25,
            height: 25,
        },
        infoText: {
            textAlign: "center",
        }

    }

    return (
        <Box sx={{ maxWidth: "48vw", flexGrow: 1 }}>
            {/* <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
                <Typography>{images[activeStep].label}</Typography>
            </Paper> */}
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    width: "-webkit-fill-available",
                                    // width: isTablet ? "32vw" : "80vw",
                                    // width: "-webkit-fill-available",
                                    height: isTablet ? "18vh" : "45vh",
                                    borderRadius: "2vh"
                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            {/* <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            /> */}
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ ...styles.stepper }}

                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === 1}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft sx={{ color: '#33BBC5' }} />
                            ) : (
                                <KeyboardArrowRight sx={{ color: '#33BBC5' }} />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight sx={{ color: '#33BBC5' }} />
                            ) : (
                                <KeyboardArrowLeft sx={{ color: '#33BBC5' }} />
                            )}
                        </Button>
                    }
                />
            </Grid>
        </Box>
    );
}

export default SwipeableContent;

import { Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material"
import { Box } from "@mui/system"
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import CopyrightIcon from '@mui/icons-material/Copyright';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { getTheme } from "../utils/theme";

const Footer = (props) => {
    const { t } = useTranslation()

    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));

    const styles = {
        mainGrid: {
            // backgroundColor: "#F8F7F7,"
            marginTop: isLaptop ? "5vh" : "10vh",
            backgroundColor: '#33BBC5',
            width: "100%",
            backgroundSize: "cover",
        },
        boxGrid: {
            height: isLaptop ? "2.5vh" : "5vh"
        },
        box: {
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            backgroundColor: "#ffffff",
            whiteSpace: "pre",
        },
        paperGrid: {
            height: isLaptop ? "5vh" : "10vh",
        },
        paper: {
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: '#2a9aa2',
        },
        paperTypography: {
            marginLeft: "0.4vh",
            display: "inline-flex",
            fontSize: isLaptop ? "1.6vh" : "2.3vh",
            alignItems: "center"
        },
        verticalLine: {
            borderLeft: "1px solid ",
            height: isLaptop ? "5vh" : "10vh",
        },
        address: {
            fontWeight: "bold",
            display: "inline-flex",
            fontSize: isLaptop ? "1vh" : "2vh",
            alignItems: "center",
        },
        padding: {
            padding: "2vh",
            marginTop: "1vh"
        },
        border: {
            border: "1px solid",
            marginTop: "2.5vh"
        },
        iconSize: {
            fontSize: isLaptop ? "1.5vh" : "2.5vh",
            alignSelf: "center"
        }

    }

    return <Grid container item sx={styles.mainGrid}>
        <Grid item container xs={12} >
            {/*Styling*/}
            <Grid item xs={isLaptop ? 0.5 : 3.5} sx={styles.boxGrid}>
                <Box sx={styles.box}> </Box>
            </Grid>

            {/*Email and Phone*/}
            <Grid item xs={isLaptop ? 11 : 5} sx={styles.paperGrid}>
                <Paper sx={styles.paper} >
                    <Grid item xs={12} textAlign="center">
                        <Typography sx={styles.paperTypography}> <EmailIcon sx={styles.iconSize} />&nbsp;{t('email')}</Typography>

                    </Grid>
                    {/* 
                    <Box sx={styles.verticalLine} />

                    <Grid item xs={6} textAlign="center">
                        <Typography sx={styles.paperTypography}>  <PhoneInTalkIcon sx={styles.iconSize} />&nbsp; +90 212 472 72 74</Typography>
                    </Grid> */}
                </Paper>
            </Grid>

            {/* Styling */}
            <Grid item xs={isLaptop ? 0.5 : 3.5} sx={styles.boxGrid}>
                <Box sx={styles.box}> </Box>
            </Grid>

        </Grid>
        {/* Logo */}
        <Grid item container xs={12} sx={styles.border} alignItems="center">
            <Grid item xs={4} textAlign="center" sx={styles.padding}>
                <img
                    width={isLaptop ? "50%" : "20%"} height={isLaptop ? "50%" : "20%"}
                    src={'/images/logo.png'}
                    alt={'name'}
                    loading="lazy"
                />
            </Grid>

            {/* Address */}
            <Box sx={styles.verticalLine} />
            <Grid item xs={4} textAlign="center" sx={styles.padding}>

                <Grid>
                    <Typography sx={{ fontSize: isLaptop ? "1vh" : "2vh" }} >
                        <Typography sx={styles.address} component={'span'} >Adres:&nbsp;</Typography>
                        Ostim Teknopark Turkuaz Bina, 100. Yıl Blv No:55 E-Blok 4. kat, Yenimahalle/Ankara
                    </Typography>
                </Grid>

                <Grid container justifyContent={"center"}>
                    <CopyrightIcon sx={styles.iconSize} />
                    <Typography sx={{ ...styles.address, fontSize: isLaptop ? "1.5vh" : "2.5vh" }}>
                        TALENT CORE HR</Typography>
                </Grid>
            </Grid>

            <Box sx={styles.verticalLine} />
            {/* Facebook LinkedIn */}
            <Grid item xs={3.9} textAlign="center" sx={styles.padding}>
                <img
                    width={isLaptop ? "50%" : "20%"} height={isLaptop ? "50%" : "20%"}
                    src={'/images/logo.png'}
                    alt={'name'}
                    loading="lazy"
                />
                {/* <IconButton aria-label="delete"  >
                    <FacebookIcon sx={{ fontSize: isLaptop ? "2.5vh" : "5vh" }} />
                </IconButton>

                <IconButton aria-label="delete"  >
                    <LinkedInIcon sx={{ fontSize: isLaptop ? "2.5vh" : "5vh" }} />
                </IconButton> */}
            </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center" sx={styles.padding}>
            <Typography sx={styles.paperTypography}>
                <CopyrightIcon sx={styles.iconSize} />
                2024 All Rights Reserved.&nbsp;
                <Typography sx={{ ...styles.paperTypography }} component={'span'} >
                    Talent Core HR
                </Typography>
                &nbsp;
            </Typography>
        </Grid>
    </Grid>

}

export default Footer

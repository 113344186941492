import React, { useState } from 'react'
import { CardMedia, Grid, IconButton, useMediaQuery } from "@mui/material"
import { getTheme } from "../utils/theme";
import { IMAGES, ROUTES } from '../utils/constants';
import TextWithBar from './TextWithBar';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';




const Navbar = () => {

    const isTablet = useMediaQuery(getTheme().breakpoints.down('laptop'));
    const navigate = useNavigate()
    const [expandedNavbar, setExpandedNavbar] = useState(false)
    const items = ["HAKKIMIZDA", "ILETISIM"]
    const itemsMobile = ["HAKKIMIZDA", "ILETISIM"]

    const handleClick = (route) => {
        if (isTablet) {
            setExpandedNavbar(false)
        }
        switch (route) {
            case "ILETISIM":
                navigate(ROUTES.CONTACT)

                break;
            case "HAKKIMIZDA":
                navigate(ROUTES.ABOUTUS)

                break;
            default:
                break;
        }
    }
    const handleNavbarExpand = () => {
        setExpandedNavbar(!expandedNavbar)

    }

    const styles = {
        main: {
            flexGrow: 1,
            height: isTablet ? "15vh" : "15vh",
            width: "100%",
            backgroundSize: "cover",


        },
        mobile: {
            flexGrow: 1,
            // backgroundColor: '#33BBC5',
            height: isTablet ? "10vh" : "15vh",
            width: "100%",
            alignItems: "center",
            backgroundSize: "cover",
            justifyContent: "space-between",
            paddingRight: "1rem",
            paddingLeft: ".5rem"

        },
        expandedMobile: {
            position: "absolute",
            top: isTablet ? "10vh" : "15vh",
            left: 0,
            zIndex: 30,
            backgroundColor: "#212529",
            opacity: 0.9,
            display: "flex",
            justifyContent: "center"

        },
        logoGrid: {

            justifyContent: "center",
            display: "flex",
            alignItems: "center"



        },
        logo: {
            boxShadow: "none",
            objectFit: 'contain',
            height: isTablet ? "8vh" : "10vh",
            animation: {}



        },
        items: {
            justifyContent: "space-evenly",
            alignItems: "center",


        },
        item: {
            alignItems: 'center',
        },


        mobileMenuIcon: {
            height: "4vh", width: "4vh"


        }
    }

    const TabletNavbar2 = () => {

        return (
            <>
                <Grid container item sx={{ ...styles.mobile, }} >
                    <Grid item xs={3} sx={{ ...styles.logoGrid }}>


                        <CardMedia sx={{ ...styles.logo, cursor: "pointer" }}
                            component="img"
                            onClick={() => navigate(ROUTES.INDEX)}

                            src={IMAGES.LOGO_MAIN}
                        />

                    </Grid>
                    <Grid item sx={{ ...styles.mobileMenuIconGrid }}  >
                        <IconButton
                            onClick={handleNavbarExpand}>
                            <MenuIcon sx={{ ...styles.mobileMenuIcon }} />
                        </IconButton>
                    </Grid>

                </Grid>
                {expandedNavbar ? <Grid container sx={{ ...styles.expandedMobile }} >

                    {
                        itemsMobile.map((item, i) =>
                            <Grid container key={item} sx={{ justifyContent: "center" }}>
                                <TextWithBar xs={12} onClick={handleClick} key={item} text={item} isAccordion={false} background="transparent"></TextWithBar>
                            </Grid>
                        )
                    }
                </Grid> : <Grid></Grid>
                }
            </>

        )
    }

    const DefaultNavbar = () => {
        return (
            <Grid container item spacing={2} sx={{ ...styles.main }} >
                <Grid item xs={3} sx={{ ...styles.logoGrid }}>

                    <Grid item>
                        <CardMedia sx={{ ...styles.logo, cursor: "pointer" }}
                            component="img"
                            onClick={() => navigate(ROUTES.INDEX)}

                            src={IMAGES.LOGO_MAIN}
                        />
                    </Grid>



                </Grid>
                <Grid item container xs={9} sx={{ ...styles.items }}  >
                    {
                        items.map((item, i) =>
                            <TextWithBar onClick={handleClick} key={item} text={item} accordionItems={""} isAccordion={false} background="transparent"></TextWithBar>

                        )
                    }
                </Grid>
            </Grid>



        )
    }




    return (

        <>
            {
                isTablet ? TabletNavbar2() : DefaultNavbar()
            }
        </>

    )
}
export default Navbar

import { Button, useMediaQuery } from "@mui/material"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { getTheme } from "../utils/theme";

const PrimaryButton = (props) => {
    const { background = '#33BBC5', styles, text = 'Dahası', ...rest } = props

    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));

    return <Button
        sx={{
            background: background,
            borderRadius: isLaptop ? "0.3vh" : "1vh",
            border: "none",
            fontSize: isLaptop ? "0.7vh" : "1.9vh",
            padding: isLaptop ? "0.6vh 1.2vh" : "1.5vh 3vh",
            color: "#fff",
            ":hover": {
                background: "#00BCD4",
                transition: "0.5s"
            },
            "& .Icon": {
                fontSize: isLaptop ? "1.5vh" : "3vh"
            },
            ...styles
        }}
        {...rest}
    >
        {text} &nbsp;&nbsp; <ArrowRightAltIcon className="Icon" />
    </Button>

}
export default PrimaryButton
import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { getTheme } from "../utils/theme";

import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

// import { useTranslation } from 'react-i18next';
import PrimaryButton from './PrimaryButton';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const SwipeableImage = (props) => {

    const { imgs, showText = true, style } = props

    const theme = useTheme();
    // const { t } = useTranslation();
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const [activeStep, setActiveStep] = React.useState(0);
    const [isAutoPlay, setIsAutoPlay] = React.useState(true)
    const maxSteps = imgs.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const styles = {
        title: {
            fontSize: "1.875rem",
        },
        paper: {
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,

            justifyContent: 'center',
        },
        stepper: {
            justifyContent: 'center',
            mt: isTablet ? "0.1rem" : '0.5rem',
            bgcolor: 'transparent',
            // justifyContent: 'center',
            '& .MuiMobileStepper-dot': {
                width: isTablet ? 12 : 18,
                height: isTablet ? 12 : 18,
                borderRadius: 20,
                backgroundColor: "#C1C1C1",
                margin: "0px 6px"
            },
            '& .MuiMobileStepper-dotActive': {
                width: isTablet ? 36 : 54,
                backgroundColor: "#33BBC5"

            }
        },
        infoContainer: {

            "& .MuiTypography-root": {
                textAlign: "left"
            },
            display: 'flex',
        },
        infoIcon: {

            width: '100vw',
            height: '56.25vw',
        },
        img: {
            width: '100%',
            height: isTablet ? "28vh" : "60vh",
            backgroundSize: "cover",
            backgroundRepeat: "round",
            padding: isTablet ? "4vh" : "15vh",
        }
    }

    return (
        <Grid container justifyContent={"center"} alignContent={'center'}>
            <Grid item xs={7} sx={{ ...style }}>
                <AutoPlaySwipeableViews
                    index={activeStep}
                    onChangeIndex={setActiveStep}
                    enableMouseEvents
                    interval={3000}
                    autoplay={isAutoPlay}
                    onMouseEnter={() => { setIsAutoPlay(false) }}
                    onMouseLeave={() => { setIsAutoPlay(true) }}
                >
                    {imgs.map((img, i) => (
                        <Grid item container xs={12} sx={{ ...styles.img, backgroundImage: img }} />

                    ))}

                </AutoPlaySwipeableViews>
            </Grid>

            {/* </Grid> */}
            <Grid item xs={12}>
                <MobileStepper
                    variant="dots"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ ...styles.stepper }}

                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === 2}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft sx={{ color: '#33BBC5' }} />
                            ) : (
                                <KeyboardArrowRight sx={{ color: '#33BBC5' }} />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight sx={{ color: '#33BBC5' }} />
                            ) : (
                                <KeyboardArrowLeft sx={{ color: '#33BBC5' }} />
                            )}
                        </Button>
                    }
                />
            </Grid>
        </Grid>

    );
}

export default SwipeableImage



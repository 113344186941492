import { Grid, Dialog, IconButton, Typography, useMediaQuery } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { getTheme } from "../../utils/theme";


const SimpleDialog = (props) => {
    const { isOpened, onClose, text } = props

    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {
        dialogPaper: {
            minWidth: isTablet ? "40vh" : "100vh"
        },
        simpleDialog: {
            overflowX: "hidden",
            justifyContent: "center",
            alignContent: "start"
        },
        typography: {
            fontSize: isTablet ? "1.25vh" : "2.5vh",
            display: "inline-flex",
        },
        typographyGrid: {
            padding: isTablet ? "2vh" : "4vh",
            paddingTop: 0
        },
        icon: {
            fontSize: isTablet ? "2.5vh" : "5vh"
        }
    }

    return <Dialog onClose={onClose} open={isOpened} PaperProps={{ sx: { ...styles.dialogPaper } }}>
        <Grid container item sx={styles.simpleDialog} xs={12}>
            <Grid item xs={12} sx={{ textAlign: "end" }}>
                <IconButton onClick={onClose} >
                    <CloseIcon sx={styles.icon} />
                </IconButton>
            </Grid>
            <Grid item xs={12} sx={styles.typographyGrid}>
                <Typography sx={styles.typography} component="span">{text}</Typography>
            </Grid>
        </Grid>
    </Dialog>
}
export default SimpleDialog

import { Grid, Typography, useMediaQuery } from "@mui/material"
import { getTheme } from "../../utils/theme";

const TextPart = (props) => {
    const { text, design } = props

    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {
        root: {
            height: !design ? isTablet ? "20vh" : "50vh" : '',
            alignContent: "start",
            padding: "1rem"
        },
        typography: {
            fontSize: design ? isTablet ? "1.1vh" : "2.2vh" : isTablet ? "1.25vh" : "2.5vh",
            display: "inline-flex",
        },
        stylingTypography: {
            height: isTablet ? "1vh" : "2vh",
            // fontWeight: "bold",
            // display: "inline-flex",
        }
    }
    return <Grid container sx={styles.root} >
        <Grid item xs={12} sx={styles.stylingTypography}>
            {/* <Typography sx={styles.stylingTypography}>
                &nbsp;
            </Typography> */}
        </Grid>
        <Grid item xs={12} >
            <Typography sx={styles.typography} component={'span'}>{text}</Typography>
        </Grid>
    </Grid>




}
export default TextPart
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { getTheme } from "../../utils/theme";

const InfoImage = (props) => {
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const content = props.content

    const styles = {

        root: {

            height: isTablet ? "30vh" : "60vh",
            width: '100%',
            backgroundImage: `url(${content})`,
            backgroundSize: "cover",
            backgroundRepeat: "round",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",

        },


    }

    return (
        <Grid item sx={styles.root}>

        </Grid>

    )
}
export default InfoImage
import { Grid, Typography, useMediaQuery } from "@mui/material";
import InfoCard from "../components/home/InfoCard";
import InfoHeader from "../components/home/InfoHeader";
import ContactUs from "../components/home/ContactUs";
import SwipeableImage from "../components/SwipeableImage";
import DashboardHeader from "../components/DashboardHeader";
import { getTheme } from "../utils/theme";

const imgs = [
    "url(/images/dashboard1.jpeg)",
    "url(/images/dashboard2.jpeg)",
    "url(/images/dashboard3.jpeg)",
];

const content1 = [
    {
        src: "/images/dashboard4.jpeg",
        text: '',
        title1: '',
        title2: 'Core ile bireysel ve ekip görevlerini izlemeye başlayın ve daha fazla görev tamamlayın.',
        mainTitle: 'Task Yönetimi'
    },
    {
        src: "/images/dashboard5.jpeg",
        text: '',
        title1: '',
        title2: `Core, gerçek zamanlı performans incelemeleri ve 360° geri bildirim değerlendirmeleriyle İK'yı basitleştirir ve riski azaltır.`,
        mainTitle: '360 derece Yetkinlik Yönetimi'
    },
]

const HomePage = () => {
    window.scrollTo(0, 0)
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    return <Grid container >
        <Grid container item>
            <Grid item xs={12}>
                <DashboardHeader text1='Hedef, Performans ve Çalışan Deneyimi ihtiyaçlarınız için' text={'tek sistem çözümü!'} />
            </Grid>
            <InfoHeader />

            <Grid item xs={12} textAlign='center'>
                <Typography fontSize={isTablet ? "2vh" : "4vh"} fontWeight='bold' >OKR Yönetimi</Typography>
            </Grid>
            <SwipeableImage imgs={imgs} />


            <InfoCard content={content1} />
        </Grid>
        {/* <Grid container item>
            <ProjectsHover />
        </Grid> */}
        <Grid container item>
            <ContactUs />
        </Grid>
    </Grid>
}
export default HomePage



const DEFAULT_LANGUAGE = 'tr';

const ROUTES = {
    ABOUTUS: '/about-us',
    CONTACT: '/contact',
    INDEX: '/'
}
const IMAGES = {
    LOGO_MAIN: "/images/logo.png",
}
const ABOUTUSBODY = [
    {
        imagePart: { text1: "Talent Core HR", text2: "Hakkında", img: "/images/corporate.jpeg" },
        textPart: <p style={{ margin: "0px" }}>
            Talentcorehr olarak vizyonumuz, işletmelerin adil, şeffaf, ölçüm odaklı ve hedef odaklı organizasyonlara dönüşmesine yardımcı olmaktır.
            <br />
            <br />
            Birçok strateji geliştirilebilir ancak başarının anahtarı uygulamadır. Odaklanmayı sürdürerek, sık sık ölçerek ve başarıyı kutlayarak-teşvik ederek en iyi uygulama elde edilir.
            Core OKR Yazılımı, Hedefler ve Temel Sonuçlar (OKR'ler) konusunda derin bir anlayışa sahip bir girişimci ve mühendis ekibi tarafından oluşturulmuştur.

            <br />
            <br />
            Talentcorehr kurucusu Emre Yılmaz, on üç yılı aşkın süredir İnsan Kaynakları Uygulamaları üzerinde çalışıyor.
            <br />
            <br />
            OKR oluşturma sürecinde sorulacak ilk soru “Neyi başarmak istiyorsunuz?” olacaktır. Cevaplanması kolay bir soru olmasa da OKR yolculuğunuza doğru başlamak için gereklidir.
            Sorulacak bir sonraki soru “Ne zamana kadar?” olacaktır. Bu hedeflere ulaşma sürecinin metrik odaklı bir yaklaşımla izlenmesi de son derece önemlidir. Bu iki kritik stratejik iş sorusunu yanıtlayacak zengin özelliklere sahip bir ürün arıyorsanız, o zaman cevabınız Core olacaktır.

        </p>
    },
]


export {
    DEFAULT_LANGUAGE,
    IMAGES,
    ABOUTUSBODY,
    ROUTES
}
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { getTheme } from "../../utils/theme";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../PrimaryButton";


const InfoText = (props) => {

    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));

    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));
    const { t, i18n } = useTranslation();

    const { content, title1, title2, year } = props

    const styles = {

        root: {
            // height: isTablet ? "15vh" : "30vh",
            // width: isTablet ? "15vh" : "30vh",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "4vw",
            paddingLeft: "4vw",
            '&:hover': {
                transform: "scale(1.0)",

            },

        },
        box: {
            width: 3,
            height: 35,
            backgroundColor: '#33BBC5',

        },


    }

    return (
        <Grid container sx={styles.root}>
            <Box sx={styles.box} />
            <Grid item xs={12}>
                <Typography fontSize={isTablet ? "2vh" : "3vh"} fontWeight='bold' color={'#33BBC5'} >{year}</Typography>
            </Grid>
            {/* <Box m={1} /> */}
            <Grid item container justifyContent="center">
                <Grid item >
                    <Typography fontSize={isTablet ? "2vh" : "4vh"}>{title1}</Typography>
                </Grid>
                <Box m={1} />
                <Grid item >
                    <Typography fontSize={isTablet ? "2vh" : "4vh"} fontWeight='bold' color={'#33BBC5'}>{title2}</Typography>
                </Grid>
            </Grid>




            <Grid item xs={12}>
                <Typography fontSize={isLaptop ? "1vh" : "1.7vh"} color={'#888888'}>{content}</Typography>
            </Grid>
            <Box m={1} />

            <Grid item xs={12}>

            </Grid>



        </Grid>
    )
}
export default InfoText
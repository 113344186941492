import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { getTheme } from "../../utils/theme";


import { useTranslation } from 'react-i18next';
import PrimaryButton from '../PrimaryButton';
import { useNavigate } from 'react-router-dom';

const Apply = () => {
    const xl = useMediaQuery(getTheme().breakpoints.down('xl'));

    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));
    const { t } = useTranslation();
    const navigate = useNavigate()

    const infoContents = {
        text: "Core Hedef ve Performans platformu size yardımcı olabilir.",
        title1: `Tüm çalışanları `,
        title2: " ortak hedefe odaklamak için;",
    }


    const styles = {
        root: {
            // height: 300,
            // maxWidth: 400,
            flexGrow: 1,
            // justifyContent: "left",
        },
        title: {
            fontFamily: 'Bold',
            fontSize: "1.875rem",
        },
        infoContainer: {
            pl: 0,
            "& .MuiTypography-root": {
                textAlign: "left"
            },
            display: 'flex',
        },
        infoText: {
            textAlign: "center",
        }

    }

    return (
        <Grid container sx={styles.root} spacing={"1vh"}>
            <Grid item container justifyContent='center' spacing={"1vh"}>
                <Grid item >
                    <Typography fontSize={isTablet ? "2vh" : "3vh"}>{t(`${infoContents.title1}`)}</Typography>
                </Grid>
                <Grid item >
                    <Typography fontSize={isTablet ? "2vh" : "3vh"} fontWeight='bold' color={'#33BBC5'}>{t(`${infoContents.title2}`)}</Typography>
                </Grid>
            </Grid>
            <Grid item container justifyContent='center' xs={12}>
                <Grid item sx={{ ...styles.infoContainer }}>
                    <Typography fontSize={xl ? "1vw" : "1.2vw"} color={'#888888'} sx={{ ...styles.infoText }}>{t(`${infoContents.text}`)}</Typography>
                </Grid>
            </Grid>
            <Grid item container justifyContent='center'>
                <Grid item>
                    <PrimaryButton text="Hemen Başla" onClick={() => navigate('/contact')} />
                    <Typography fontSize={xl ? "1vw" : "1.2vw"} sx={{ ...styles.infoText }}>7 gün ücretsiz erişim</Typography>
                </Grid>
            </Grid>
        </Grid>

    );
}

export default Apply



import { Grid, useMediaQuery } from "@mui/material"
import { useState } from "react"
import ImagePart from "../components/ourPolicy/ImagePart"
import SimpleDialog from "../components/ourPolicy/SimpleDialog"
import TextPart from "../components/ourPolicy/TextPart"
import Header from "../components/Header"
import PrimaryButton from "../components/PrimaryButton"
import { ABOUTUSBODY } from "../utils/constants"
import { getTheme } from "../utils/theme"

const AboutUs = () => {
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {
        padding: {
            paddingLeft: isTablet ? "2vh" : "8vh",
            paddingRight: isTablet ? "2vh" : "8vh",
            paddingTop: isTablet ? "2vh" : "6vh",
        },
        buttonGrid: {
            textAlign: "center",
            padding: "2.5vh"
        },
        button: {
            borderRadius: isTablet ? "0.3vh" : "1vh",
            fontSize: isTablet ? "0.75vh" : "1.9vh",
            padding: isTablet ? "0.6vh 1.2vh" : "1.5vh 3vh",
            "& .Icon": {
                fontSize: isTablet ? "1.5vh" : "3vh"
            },
        }
    }

    const text = "HAKKINDA"

    const body = ABOUTUSBODY

    const [isOpened, setIsOpened] = useState(false)
    const [textPart, setTextPart] = useState("")

    const onClose = () => {
        setIsOpened(false)
    }

    return <Grid container>
        <Grid item xs={12}>
            <Header text={text} />
        </Grid>

        {body.map((b, i) => {
            return i % 2 === 0 ?
                <Grid item container xs={12} key={i} >
                    <Grid item xs={6} sx={styles.padding}>
                        <ImagePart text1={b.imagePart.text1} text2={b.imagePart.text2} img={b.imagePart.img} />
                    </Grid>
                    <Grid item xs={6} sx={{ ...styles.padding, overflow: "hidden" }}>
                        <TextPart text={b.textPart} />
                    </Grid>
                    <Grid item xs={6} >
                    </Grid>
                    <Grid item xs={6} sx={styles.buttonGrid}>
                        <PrimaryButton styles={styles.button} onClick={() => {
                            setIsOpened(true)
                            setTextPart(b.textPart)
                        }} />
                    </Grid>

                </Grid>
                :
                <Grid item container xs={12} key={i}>
                    <Grid item xs={6} sx={{ ...styles.padding, overflow: "hidden" }}>
                        <TextPart text={b.textPart} />
                    </Grid>
                    <Grid item xs={6} sx={styles.padding}>
                        <ImagePart text1={b.imagePart.text1} text2={b.imagePart.text2} img={b.imagePart.img} />
                    </Grid>
                    <Grid item xs={6} sx={styles.buttonGrid}>
                        <PrimaryButton styles={styles.button} onClick={() => {
                            setIsOpened(true)
                            setTextPart(b.textPart)
                        }} />
                    </Grid>
                </Grid>
        }
        )}
        <SimpleDialog isOpened={isOpened} onClose={onClose} text={textPart} />
    </Grid>
}

export default AboutUs
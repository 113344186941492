import { Grid, TextField, useMediaQuery } from "@mui/material"
import { getTheme } from "../../utils/theme";


const InputBox = (props) => {

    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));
    const { label, StartIcon } = props


    const styles = {
        box: {
            bgcolor: 'grey.200',
            border: 1,
            borderRadius: '1vh',
            justifyContent: "left",
            alignItems: "center",
            padding: "1vh",
            '&:hover': {
                borderColor: "rgba(0, 0, 0, 0.87)"
            }
        },
        textField: {
            paddingLeft: "1vh",
            '& .MuiOutlinedInput-notchedOutline': {
                border: "none",
                borderLeft: 1,
                borderRadius: 0,
                borderColor: 'grey.500',
            },
        }
    }

    return (
        <Grid container item sx={{ ...styles.box, borderColor: 'grey.500' }} >
            <Grid item>
                <StartIcon sx={{ color: 'action.active', fontSize: isLaptop ? "2.5vh" : "5vh", mt: "1vh" }} />
            </Grid>
            <Grid item xs={10}>
                <TextField
                    sx={styles.textField}
                    InputLabelProps={{ style: { fontSize: isLaptop ? "1.25vh" : "2.5vh", justifySelf: "center", } }}
                    inputProps={{ style: { fontSize: isLaptop ? "1.5vh" : "3vh", height: isLaptop ? "2vh" : "4vh", justifySelf: "center" } }}
                    label={label}
                    size="small"
                />
            </Grid>
        </Grid>
    )
}
export default InputBox
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { getTheme } from "../utils/theme";

const Header = (props) => {
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {
        root: {
            height: isTablet ? "25vh" : "50vh",
            width: '-webkit-fill-available',
            backgroundImage: 'url(/images/banner.jpg)',
            backgroundSize: "cover",
            // backgroundRepeat: "round",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "5vh",
        },
        typography: {
            fontSize: isTablet ? "3vh" : "6vh",
            fontWeight: "bold",
            display: "inline-flex",
        }
    }
    const { text = 'İçin', text1 = 'TALENT CORE HR' } = props
    return <Grid container item xs={12} sx={styles.root}>
        <Typography color="white" sx={styles.typography}>{text1} <Typography color="#2a9aa2" sx={styles.typography} component={'span'}>&nbsp;{text} </Typography></Typography>
    </Grid>
}
export default Header
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "../pages/AboutUs";
import HomePage from "../pages/HomePage";
import { ROUTES } from "../utils/constants";
import PublicRoute from "./PublicRoute";
import Contact from "../pages/Contact";


const AppRouter = () => {


    return <BrowserRouter>
        <Routes>
            <Route exact path={ROUTES.INDEX} element={<PublicRoute component={HomePage} />} />
            <Route exact path={ROUTES.ABOUTUS} element={<PublicRoute component={AboutUs} />} />
            <Route exact path={ROUTES.CONTACT} element={<PublicRoute component={Contact} />} />
            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </Routes>
    </BrowserRouter>



}

export default AppRouter

import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTranslation } from "react-i18next";
import { getTheme } from "../../utils/theme";
import InfoImage from "./InfoImage";
import InfoText from "./InfoText";

const InfoCard = ({ content }) => {
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const { t } = useTranslation();


    const styles = {

        root: {
            // height: isTablet ? "15vh" : "30vh",
            // width: isTablet ? "15vh" : "30vh",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",


        },
        main2: {
            // height: isTablet ? "15vh" : "30vh",
            // width: isTablet ? "15vh" : "30vh",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",

            // '&:hover': {
            //     transform: "scale(1.2)",

            // },
        },

    }

    return (<>
        <Grid container sx={styles.root} spacing={5}>
            {content.map((c, i) => {
                return (
                    <Grid item container key={i}>
                        <Grid item xs={12} textAlign='center'>
                            <Typography fontSize={isTablet ? "2vh" : "4vh"} fontWeight='bold' >{c.mainTitle}</Typography>
                        </Grid>
                        {
                            i % 2 === 1 ?
                                <Grid item container sx={styles.main2}>
                                    <Grid item xs={6} sx={{ overflow: "hidden" }}>
                                        <InfoImage content={c.src} index={i + 1} />
                                    </Grid>

                                    <Grid item xs={6} sx={{ overflow: "hidden" }}>
                                        <InfoText content={c.text} title1={c.title1} title2={c.title2} year={c.year} index={i + 1} />
                                    </Grid>
                                </Grid>
                                :

                                <Grid item container sx={styles.main2}>

                                    <Grid item xs={6} sx={{ overflow: "hidden" }}>
                                        <InfoText content={c.text} title1={c.title1} title2={c.title2} year={c.year} index={i + 1} />
                                    </Grid>

                                    <Grid item xs={6} sx={{ overflow: "hidden" }}>
                                        <InfoImage content={c.src} index={i + 1} />
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                )
            })}


        </Grid>
    </>
    )
}
export default InfoCard
import { Box, Grid, Typography, useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants";
import { getTheme } from "../../utils/theme";
import rotateStyle from './RotateBorder.module.css'

const ContactUs = () => {
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const navigate = useNavigate()

    const content = [
        { src: "/images/icon2.png", text: "ILETISIM", href: ROUTES.CONTACT },
    ]

    const mainHeight = isTablet ? "15vh" : "25vh"
    const boxGridHeight = isTablet ? "18vh" : "30vh"
    const marginTop = isTablet ? 18 - 15 / 2 + "vh" : 30 - 25 / 2 + "vh"

    const styles = {
        root: {
            marginTop: "5vh",
            justifyContent: "center",
            marginBottom: isTablet ? "7.5vh" : "12.5vh"
        },
        main: {
            height: mainHeight,
            width: isTablet ? "14vh" : "20vh",
            zIndex: 1,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            background: "#2a9aa2",
            borderRadius: "5px",
            transition: "0.5s",
            cursor: "pointer",
            '&:hover': {
                transform: "scale(1.2)",
                background: "#25878e",
                transition: "0.5s"
            },
        },
        iconBorder: {
            borderRadius: "100%",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            height: isTablet ? "8vh" : "12vh",
            width: isTablet ? "8vh" : "12vh",
            border: "1px dashed #FFFFFF",
            // marginTop: isTablet ? "2.8vh" : "7vh",
        },
        icon: {
            background: "#FFFFFF",
            borderRadius: "100%",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            height: isTablet ? "7vh" : "10vh",
            width: isTablet ? "7vh" : "10vh",
        },
        typography: {
            justifyContent: "center",
            marginTop: "0.5vh"
        },
        boxGrid: {

        },
        box: {
            // width: "-webkit-fill-available",
            // height: "-webkit-fill-available",
            backgroundColor: "#33BBC5",
            whiteSpace: "pre",
            borderRadius: "5px",
            height: boxGridHeight,
            width: `${22 * content.length + 15}vh`,
            justifyContent: "center",
            alignContent: "center",
        },
        mainGrid: {
            justifyContent: "center",
            position: "absolute",
            marginTop: marginTop
        },
        contactUs: {
            marginBottom: isTablet ? "5vh" : "10vh",
            fontSize: isTablet ? "2.5vh" : "5vh"
        }
    }


    return (
        <Grid container item sx={styles.root}>
            <Grid item container sx={{ justifyContent: "center" }}>
                {/* Styling */}
                <Grid item container sx={styles.box}>
                    <Typography color="white" sx={styles.contactUs}>ILETISIM</Typography>
                </Grid>
            </Grid>

            <Grid item container sx={styles.mainGrid}>
                {content.map((c, i) => {
                    return (
                        <Grid container item sx={{ ...styles.main, marginLeft: i === 0 ? 0 : isTablet ? "1vh" : "2vh" }} key={i} onClick={() => navigate(c.href)}>
                            <Grid item container sx={styles.iconBorder} className={rotateStyle.iconBorder} >
                                <Grid item container sx={styles.icon} className={rotateStyle.iconNoRotate}>
                                    <img
                                        style={{
                                            width: isTablet ? "3.2vh" : "calc(100% - 4vh)",
                                            height: isTablet ? "3.2vh" : "calc(100% - 4vh)"
                                        }}

                                        src={c.src}
                                        alt={'name'}
                                        loading="lazy"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} sx={styles.typography} >
                                <Typography color={"white"} fontSize={isTablet ? "1vh" : "2vh"}>{c.text}</Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
export default ContactUs
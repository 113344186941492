import { Grid, useMediaQuery } from "@mui/material"
import Header from "../components/Header"
import { useLocation } from 'react-router-dom'
import { ROUTES } from "../utils/constants"
import TextPart from "../components/contact/TextPart"
import ContactFields from "../components/contact/ContactFields"
import { getTheme } from "../utils/theme"

const Contact = (props) => {
    const header = 'İletişim'
    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));

    const styles = {
        padding: {
            paddingLeft: isLaptop ? "5vh" : "15vh",
            paddingRight: isLaptop ? "5vh" : "15vh"
        }
    }


    return <Grid container item  >
        <Grid item xs={12}>
            <Header text1={header} />
        </Grid>
        <Grid container sx={styles.padding}>
            <Grid item xs={isLaptop ? 12 : 3} sx={{ mb: "2vh" }}>
                <TextPart text={header} />
            </Grid>
            <Grid item xs={isLaptop ? 12 : 9}>
                <ContactFields />
            </Grid>
        </Grid>
    </Grid>
}
export default Contact
import { ThemeProvider, Typography } from '@mui/material';
import './App.css';
import { getTheme } from './utils/theme';
import './utils/i18n'; // available for all components through context api
import CssBaseline from '@mui/material/CssBaseline';
import AppRouter from './router/AppRouter';



function App() {
  return (
    <ThemeProvider theme={getTheme()}>

      <CssBaseline />
      <AppRouter />

    </ThemeProvider>
  );
}

export default App;
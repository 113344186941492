

export default {

    tr: {
        translation: {
            email: "info@talentcorehr.com",
        }
    },

    en: {
        translation: {




        }
    },
}

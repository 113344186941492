import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { getTheme } from '../../utils/theme';

const TextPart = (props) => {
    const { text } = props

    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));

    const styles = {
        icon: {
            fontSize: isLaptop ? "6vh" : "12vh",
            color: "#2a9aa2",
        },
        typography: {
            fontSize: isLaptop ? "3vh" : "6vh",
            fontWeight: "bold",
            display: "inline-flex",
        },
        typography2: { fontSize: isLaptop ? "1vh" : "2vh" },
        align: {
            textAlign: "center",
            alignItems: "center"
        }
    }
    return <Grid item container>
        <Grid item container xs={12} sx={styles.align}>
            <AddCircleIcon sx={styles.icon} />
            {isLaptop && <Typography color="#2a9aa2" sx={styles.typography}>{text} <Typography color="black" sx={styles.typography} component={'span'}>&nbsp;İçin; </Typography></Typography>}

        </Grid>
        {!isLaptop && <Typography color="#2a9aa2" sx={styles.typography}>{text} <Typography color="black" sx={styles.typography} component={'span'}>&nbsp;İçin; </Typography></Typography>}
        <Grid item xs={12}>
            <Typography color="#686868" sx={styles.typography2}>Bilgileri eksiksiz doldurun, en kısa sürede size geri dönüş yapacağız.</Typography>
        </Grid>
    </Grid>
}

export default TextPart
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { height } from "@mui/system";
import { getTheme } from "../../utils/theme";
import SwipeableContent from "../design/SwipeableContent";

const ImagePart = (props) => {
    const { design } = props
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {
        img: {
            width: "-webkit-fill-available",
            height: isTablet ? "20vh" : "50vh",
            borderRadius: "2vh"
        },
        typography: {
            fontSize: design ? isTablet ? "1vh" : "2.7vh" : isTablet ? "1.8vh" : "6vh",
            fontWeight: "bold",
            display: "inline-flex",
        }
    }
    const { text1, text2, img, slide } = props
    return (<Grid container>
        <Grid item xs={12}>
            <Typography color="#2a9aa2" sx={styles.typography} component={'span'}>{text1} <Typography color="black" sx={styles.typography}>&nbsp;{text2} </Typography> </Typography>
        </Grid>
        {img && <img
            style={styles.img}
            src={img}
            alt={'name'}
            loading="lazy"
        />}
        {slide && <SwipeableContent slide={slide}></SwipeableContent>}
    </Grid>

    )
}
export default ImagePart
import { Button, Box, Grid, useMediaQuery, Select, MenuItem } from "@mui/material"
import { getTheme } from "../utils/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../utils/constants";
import { useEffect, useState } from "react";


const TextWithBar = (props) => {
    const { text, isAccordion, onClick, accordionItems } = props
    const navigate = useNavigate()
    const isTablet = useMediaQuery(getTheme().breakpoints.down('laptop'));

    const [value, setValue] = useState('')
    const params = useLocation()

    useEffect(() => {
        if (params.pathname === ROUTES.IRAQ
            || params.pathname === ROUTES.INDEX
            || params.pathname === ROUTES.CONSTRUCTION
            || params.pathname === ROUTES.ENERGY) {
            setValue('')
        }
    }, [params.pathname])


    const handleChange = (event) => {

        setValue(event.target.value)

        switch (event.target.value) {
            case ("HAKKIMIZDA"):
                navigate(ROUTES.ABOUTUS)

                break;
            case ("OUR POLICY"):
                navigate(ROUTES.OURPOLICY)

                break;
            case ("OUR TEAM"):
                navigate(ROUTES.OURTEAM)

                break;
            case ("ILETISIM"):
                navigate(ROUTES.CONTACT)

                break;
            case ("APPLY US"):
                navigate(ROUTES.APPLY)

                break;

            default:
                break;
        }
    };
    const handleClick = (event) => {

        onClick(event);

    }




    const isLaptop = useMediaQuery(getTheme().breakpoints.down('laptop'));
    const createMenuItem = (accordionItems) => {
        const menuList = []
        accordionItems.map((item, i) => {
            var menu = <MenuItem key={i} value={item} sx={{ ...styles.accordionTitle }}>{item}</MenuItem>
            menuList.push(menu)
            return "";

        })
        return menuList;

    }
    const styles = {
        main: {
            "&:hover": {
                '& .hoverBar': {

                    transform: "scaleX(1)",
                    transition: "transform 0.8s",
                    color: "yellow",
                    backgroundColor: "#2a9aa2",
                    transformOrigin: "bottom left",
                },
                "& .hoverButton": {

                    background: "transparent",
                    // fontSize: isLaptop ? "0.8vh" : "2.5vh",
                    transition: "0.8s",
                    color: "#656565",
                    fontWeight: "bold",
                },
                "& .hoverAccordion": {
                    fontSize: isLaptop ? "2vh" : "2.5vh",
                    transition: "0.8s",
                },
            }
        },
        button: {
            fontSize: isLaptop ? "3vh" : "2vh",
            //paddingBottom: "0rem",
            color: isTablet ? "white" : "#686868",

        },
        loading: {
            height: "10px",
            transform: "scaleX(0)",
            display: "flex"

        },

        accordionTitle: {
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
            },
            color: isTablet ? "white" : "#686868",
            fontSize: isLaptop ? "3vh" : "2vh",
        },
    }
    const DefaultButton = () => {
        return (
            <Grid alignItems="center" sx={{
                ...styles.main,
            }} >
                <Grid item >
                    <Button variant="text" className="hoverButton" onClick={() => handleClick(text)} sx={{

                        ...styles.button
                    }}>{text}
                    </Button>

                    <Grid item>
                        <Box className="hoverBar" sx={{ ...styles.loading }}></Box>
                    </Grid>
                </Grid> </Grid>
        )


    }

    const Accordionbutton = () => {
        return (
            <Grid direction="column" sx={{ ...styles.main }}   >
                <Grid item   >
                    <Box >


                        <Select
                            value={''}
                            displayEmpty
                            sx={{ ...styles.accordionTitle }}
                            className="hoverButton"
                            onChange={handleChange}
                            renderValue={() => text} >

                            {

                                createMenuItem(accordionItems)
                            }
                        </Select>


                    </Box>


                </Grid>

                <Grid item>
                    <Box className="hoverBar" sx={{ ...styles.loading }}></Box>
                </Grid>

            </Grid>

        )
    }

    return (
        <>
            {
                isAccordion ? Accordionbutton() : DefaultButton()

            }
        </>

    )
}
export default TextWithBar
import { Grid, useMediaQuery } from "@mui/material"
import { getTheme } from "../../utils/theme";
import Apply from "./Apply";


const InfoHeader = () => {
    const isTablet = useMediaQuery(getTheme().breakpoints.down('tablet'));

    const styles = {

        pictures: {

            // textAlign: "center",
            // justifyContent: "center",
            // alignItems: "center",
            height: isTablet ? "23vw" : "16vw",
            width: isTablet ? "23vw" : "16vw",
            marginBottom: "3vw",
            '&:hover': {
                transform: "scale(1.1)",

            },

        },
        sideBar: {
            zIndex: "5",

        },
        apply: {
            // padding: "2vh",
            placeSelf: 'center',
            paddingLeft: isTablet ? "2vh" : "8vh",
            paddingRight: isTablet ? "2vh" : "8vh",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            '&:hover': {
                transform: "scale(1.05)",

            },


        },


    }

    return (
        <>
            <Grid container item >
                <Grid item xs={5} sx={styles.apply}>
                    <Apply />
                </Grid>

                <Grid item xs={7}>

                    <Grid item textAlign='center'>
                        <img
                            src="/images/talents.png"
                            alt={'name'}
                            loading="lazy"
                            style={{
                                height: isTablet ? '50vw' : "25vw",
                                width: isTablet ? '50vw' : "25vw",
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default InfoHeader